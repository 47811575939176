import React, { useEffect, useState, Component } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import NavBar from "../components/NavBar";
import FrTextInput from "../components/FrTextInput";
import FrSelect from "../components/FrSelect";
import UserOptions from "../components/UserOptions";
import FrLoading from "../components/FrLoading";
import api from "../services/api";
import "./styles/DefaultReg.css";
import FooterDelphus from "../components/FooterDelphus";
import { mCPF, mCNPJ, mData, mHora } from "../utils/masks";
import FrTableReg from "../components/FrTableReg";
import { onlyNumbers, paramsToObject } from "../utils/functions";
import { Modal } from "react-bootstrap";
import TableEdicao2 from "../components/TableEdicao2";
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { SettingsInputAntennaRounded } from "@material-ui/icons";

const swal = withReactContent(Swal);

function Reservas() {
  const [data, setData] = useState({});
  const [updateType, setUpdateType] = useState(false);
  const [enableEdit, setEnableEdit] = useState("S");
  const [exclusiva, setExclusiva] = useState('');
  const [erroHorario, setErroHorario] = useState(false);
  const [comboLoc, setComboLoc] = useState([{ value: "", display: "" }]);
  const [comboMat, setComboMat] = useState([{ value: "", display: "" }]);
  const [comboTpe, setComboTpe] = useState([{ value: "", display: "" }]);
  const [comboTurno, setComboTurno] = useState([{ value: "", display: "" }]);
  const [auth, setAuth] = useState(
    JSON.parse(
      localStorage["authApergs"] ||
      JSON.stringify({ authenticated: false, user: 0 })
    )
  );
  const [comboFinalidade, setComboFinalidade] = useState([
    { value: "", display: "" },
  ]);
  const [dataMat, setDataMat] = useState([{ value: "", display: "" }]);
  const [dataMat2, setDataMat2] = useState([{ id: 0, pk_mat: 0, nomemat: "", qtd_estoque: 0 }]);
  const [dataMat3, setDataMat3] = useState([{ id: 0, pk_mat: 0, nomemat: "", qtd_estoque: 0 }]);
  const [dataRau, setDataRau] = useState([{ value: "", display: "" }]);
  const [taxa, setTaxa] = useState(0);
  const [comboAss, setComboAss] = useState([{ value: "", display: "" }]);
  const [showModal, setShowModal] = React.useState(false);
  const [valor, setValor] = useState(0);

  const columns = [
    {
      id: "display",
      numeric: false,
      label: "Material / Equipamento",
      align: "left",
      minWidth: 200,
    },
    {
      id: "quantidade",
      numeric: true,
      label: "Qtd.",
      align: "left",
      minWidth: 90,
      editable: true
    },
  ];

  const columnsRau = [
    {
      id: "display",
      numeric: false,
      label: "Nome",
      align: "left",
      minWidth: 200,
    },
    { id: "cpf", numeric: false, label: "CPF", align: "left", minWidth: 90 },
    { id: "cnpj", numeric: false, label: "CNPJ", align: "left", minWidth: 90 },
  ];

  

  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });

      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams);

      await api.get("/materiais/grid?pk_res=" + query.codigo).then(async (r) => {
        setDataMat2(r.data);

      });

      setComboMat(await api.getComboData("materiais"));
      setComboAss(await api.getComboData("associados"));
      setComboLoc(await api.getComboData("local"));
      setComboTpe(await api.getComboDataWhere("tipo_evento", 1));
      setComboTurno([
        {
          value: "M",
          display: "MANHÃ",
        },
        {
          value: "T",
          display: "TARDE",
        },
        {
          value: "N",
          display: "NOITE",
        },
      ]);


      if (query.codigo) {
        setUpdateType(true);

        api.get("/reservas/pegaRau?pk_res=" + query.codigo).then(async (r) => {
          if (r.data.length > 0) setDataRau(r.data);
        });

        api.get("/reservas/pegaMat?pk_res=" + query.codigo).then(async (r) => {
          if (r.data.length > 0) setDataMat(r.data);
        });

        api.get("/reservas?pk_res=" + query.codigo).then(async (r) => {
          if (r.data) {
            setComboFinalidade(
              await api.getComboDataWhere(
                "reservas",
                "fk_loc=" + r.data[0].fk_loc
              )
            );
            r.data[0].data = r.data[0].data_format;
            r.data[0].hora_inicio = r.data[0].format_hora_inicio;
            r.data[0].previsao_termino = r.data[0].format_previsao_termino;
            r.data[0].reserva_exclusiva = r.data[0].exclusiva;
            setExclusiva(r.data[0].reserva_exclusiva)
            setValor(r.data[0].valor_locacao)
            
            setData(r.data[0]);

            api.get("/local?pk_loc=" + r.data[0].fk_loc).then((r) => {
              if (r.data[0].taxa_reserva_exclusiva > 0)
                setTaxa(r.data[0].taxa_reserva_exclusiva);
              else setTaxa(0);
            });

            if (r.data[0].dias_ate_evento < r.data[0].diasedicaoreserva) {
              setEnableEdit("N");
              swal.fire({
                html:
                  r.data[0].dias_ate_evento < 0
                    ? "Evento com data passada! Edição da reserva não permitida!"
                    : " Faltam " +
                    r.data[0].dias_ate_evento +
                    " para o evento! Edição da reserva não permitida!",
                showConfirmButton: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
              });
            }
          }
        });
      } else {
        const auxValues = { ...data };
        auxValues["fk_loc"] = query.fk_loc;
        auxValues["data"] = query.data;
        auxValues["exclusiva"] = query.reserva_exclusiva;
        auxValues["reserva_exclusiva"] = query.reserva_exclusiva;
        setExclusiva(auxValues["reserva_exclusiva"])

        setData(auxValues);
        api.get("/local?pk_loc=" + query.fk_loc).then((r) => {
          if (r.data[0].taxa_reserva_exclusiva > 0)
            setTaxa(r.data[0].taxa_reserva_exclusiva);
          else setTaxa(0);
        });

        setComboFinalidade(
          await api.getComboDataWhere("reservas", "fk_loc=" + query.fk_loc)
        );
      }

      swal.close();

      await api.get("/associados/?pk_ass=" + auth.pk_ass).then(async (r) => {
        if (r.data[0].fk_sit == 3) {
          swal
            .fire({
              html: "Associado inativo! Entre em contato com a Secretaria!",
              showConfirmButton: true,
              allowOutsideClick: false,
              allowEscapeKey: false,
            })
            .then((result) => {
              window.history.back();
            });
        }
      });
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const auxValues = { ...data };
      console.log(auxValues);
      if(auxValues["fk_lva"] ){
        await api.get("/reservas/valores?pk_lva=" + auxValues["fk_lva"]).then(async (r) => {
          setValor(r.data[0].valor);
          auxValues["reserva_exclusiva"] = r.data[0].exclusiva;
          setExclusiva(r.data[0].exclusiva);
          console.log(auxValues)
          // if (finalidade == "P")
          //   auxValues["valor_locacao"] = r.data[0].valor_locacao;
          // else if (finalidade == "T")
          //   auxValues["valor_locacao"] = r.data[0].valor_locacao_1g;
          // else if (finalidade == "O")
          //   auxValues["valor_locacao"] = r.data[0].valor_locacao_2g;
          // else if (finalidade == "A")
          //   auxValues["valor_locacao"] = 0;
        });
      }
      setData(auxValues);
    };

    fetchData();
  }, [data.fk_lva]);

  function consistData(data) {
    let required = [
      "data",
      "fk_loc",
      "hora_inicio",
      "previsao_termino",
      "turno",
      "fk_lva",
      "fk_tpe",
      "num_pessoas",
    ];
    let errors = [];
    if (erroHorario) errors.push('Horário')

    required.forEach((curr, index) => {
      let fieldName = curr;
      if (curr === "data") fieldName = "Data Pretendida";
      if (curr === "fk_loc") fieldName = "Dependência a ser ocupada";
      if (curr === "hora_inicio") fieldName = "Hora Início";
      if (curr === "previsao_termino") fieldName = "Previsão Término";
      if (curr === "fk_tpe") fieldName = "Tipo de Evento";
      if (curr === "num_pessoas") fieldName = "Nº de Pessoas";

      if (!data[curr]) return errors.push(fieldName);
    });

    return errors;
  }

  function confirmaReserva(jaTemReserva){
    swal
    .fire({
      title: "ATENÇÃO",
      text:
        jaTemReserva && exclusiva == 'S' ? 'Já existe reserva em outro local. Não permitido reserva exclusiva. Confirma reserva normal?'
        :("Deseja salvar " +
        (updateType ? "as alterações" : "a inclusão") +
        " do registro?"),
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
    })
    .then((result) => {
      if (result.value) {
        const auxValues = { ...data };
        auxValues.pk_ass = auth.pk_ass;
        auxValues.reserva_exclusiva = jaTemReserva ? 'N' : exclusiva
        auxValues.exclusiva = auxValues.reserva_exclusiva;
        auxValues.valor_locacao = valor;          

        let errors = consistData(auxValues);

        api.get("/local?pk_loc=" + auxValues["fk_loc"]).then((r) => {
          if (r.data[0].capacidade < auxValues["num_pessoas"]) {
            swal.fire({
              title: "Erro!",
              text: "O NÚMERO DE PESSOAS É MAIOR QUE A CAPACIDADE DO LOCAL!",
              icon: "warning",
            });
            errors.push(
              "O NÚMERO DE PESSOAS É MAIOR QUE A CAPACIDADE DO LOCAL!"
            );
          }

          if (!updateType) {
            api
              .get(
                "/reservas/consisteReserva?pk_loc=" +
                auxValues["fk_loc"] +
                "&data=" +
                auxValues["data"] +
                "&turno=" +
                auxValues["turno"]
              )
              .then((r) => {
                if (
                  r.data[0] &&
                  r.data[0].pk_res > 0 &&
                  errors.length === 0
                ) {
                  swal
                    .fire({
                      html: "JÁ EXISTE EVENTO CONFIRMADO NESTE LOCAL NA DATA INFORMADA! GOSTARIA DE FICAR EM LISTA DE ESPERA?",
                      showCancelButton: true,
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      confirmButtonText: "Sim",
                      cancelButtonText: "Não",
                    })
                    .then((result) => {
                      if (result.value) {
                        auxValues.pk_ass = auth.pk_ass;
                        // auxValues.data = dateSql(auxValues.data)
                        api
                          .post("/reservas/listaEspera", auxValues)
                          .then((r) => {
                            if (r.status === 200) {
                              swal
                                .fire({
                                  title:
                                    "Registro lançado em lista de espera",
                                  text: "O registro foi lançado em lista de espera!",
                                  icon: "success",
                                })
                                .then((result) => {
                                  if (result.value) {
                                    window.history.back();
                                  }
                                });
                            }

                            //api lista espera
                          });
                      } else window.history.back();
                    });
                } else {
                  if (errors.length === 0) {
                    auxValues.dataMat = dataMat;
                    auxValues.dataRau = dataRau;
                    if (auth.pk_ass == 1973)
                      //apergs escolhe qq associado
                      auxValues.pk_ass = auxValues.fk_ass;
                    else auxValues.pk_ass = auth.pk_ass;

                    api.post("/reservas/add", auxValues).then((r) => {
                      if (r.status === 200) {
                        swal
                          .fire({
                            title: "Registro Adicionado",
                            text: "O registro foi adicionado com sucesso!",
                            icon: "success",
                          })
                          .then((result) => {
                            if (result.value) {
                              window.history.back();
                            }
                          });
                      }
                    });
                  } else {
                    swal.fire({
                      title: "Erro!",
                      text: "Verifique o(s) campo(s) " + errors.join(","),
                      icon: "warning",
                    });
                  }
                }
              });
            //api get local
          } else {
            if (errors.length === 0) {
              auxValues.dataMat = dataMat;
              auxValues.dataRau = dataRau;

              api.post("/reservas/edit", auxValues).then((r) => {
                console.log(r);
                if (r.status === 200) {
                  swal
                    .fire({
                      title: "Registro Alterado",
                      text: "O registro foi alterado com sucesso!",
                      icon: "success",
                    })
                    .then((result) => {
                      if (result.value) {
                        window.history.back();
                      }
                    });
                }
                //api consiste reserva
              });
            }
          }
        });
      }
    });
  }


  function handleSubmit(e) {
    e.preventDefault();
    let jaTemReserva = false
    if (exclusiva == 'S') {
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
      api.get('/reservas/jaTemReserva?data='+data.data + (updateType ? '&pk_res='+data.pk_res : '' )).then(resReserva => {
        jaTemReserva = resReserva.data.length > 0

        confirmaReserva(jaTemReserva)
        
      })
    } else confirmaReserva(jaTemReserva)
  }


  function handleCancel(e) {
    e.preventDefault();
    swal
      .fire({
        title: "ATENÇÃO",
        text: "Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      })
      .then((result) => {
        if (result.value) {
          window.history.back();
        }
      });
  }

  function escondeModal(e) {
    e.preventDefault();
    setShowModal(false)
    console.log(dataMat3)
    if (dataMat3.length > 0) {
      let item = []
      var i;
      for (i = 0; i < dataMat3.length; i++) {
        if (dataMat3[i].qtd > 0)
          item.push({
            value: dataMat3[i].id,
            display: dataMat3[i].nomemat,
            quantidade: dataMat3[i].qtd,
            pk: dataMat3[i].id,
          });
        if (i + 1 == dataMat3.length) {
          setDataMat(item)
        }
      }
    }
  }

  // useEffect (() => {
  //   const auxValues = {...data}
  //   const fetchExclusiva = async () => {
  //     console.log('aqui passou')
  //     await api.get("/reservas/combo?pk_lva=" + data.finalidade).then( (r) => {
  //       auxValues.reserva_exclusiva = r.data[0].exclusiva
  //       setData(auxValues)
        
  //     })
  //   } 
  //   fetchExclusiva()
  // }, [data.finalidade])

  // function changeExclusiva(e){
  //   e.preventDefault()   
  //   api.get("/reservas/combo?pk_lva=" + e.target.value).then( (r) => {  
  //     console.log(r.data[0].exclusiva)      
  //     return r.data[0].exclusiva  
  //   })
    
    
  // }

  const handleChange = (e) => {
    const auxValues = { ...data };
    switch (e.target.name) {

      case 'num_pessoas':
        auxValues[e.target.name] = onlyNumbers(e.target.value)
        break

      case 'reserva_exclusiva':
        exclusiva === "S" ? setExclusiva("N") : setExclusiva("S");
        auxValues[e.target.name] = exclusiva;
        break

      case "fk_lva":   
        auxValues[e.target.name] = e.target.value;
        break;

      case "fk_mat":
        api.get("/materiais?pk_mat=" + e.target.value).then((r) => {
          auxValues["nomemat"] = r.data[0].descricao;
          auxValues["qtd_estoque"] = r.data[0].qtd_estoque;
        });
        auxValues[e.target.name] = e.target.value;
        break;

      case "data":
        auxValues[e.target.name] = mData(e.target.value);
        break;

      case "hora_inicio":
      case "previsao_termino":
        auxValues[e.target.name] = mHora(e.target.value);
        break;

      default:
        auxValues[e.target.name] = e.target.value;
    }
    setData(auxValues);
    console.log(data)
  };

  const verificaHora = (e) =>{
    e.preventDefault()

    let horario = 0
    let horaBusca = 0
    if (e.target.value){
      horario = Number(e.target.value.replace(':','.'))
    } 
    let id = e.target.id
    if (e.target.name == 'hora_inicio'){
      console.log(horario)
      api.get('/local?pk_loc='+data.fk_loc).then(r=>{
        horaBusca= r.data[0].primeiro_horario.replace(':','.').split(':')[0]
        horaBusca= Number(horaBusca)
        console.log(horaBusca > horario)
        if (horaBusca > horario ){
          swal.fire({
                  title: "Erro!",
                  text: "Horário informado fora do período de funcionamento!",
                  icon: "warning",
                }).then(r=>{
                  setErroHorario(true)
                  // if(!para){
                  //   setPara(true)
                  //   document.getElementById(id).focus()

                  // }
                });
        } else {
          setErroHorario(false)
        }
      })
    } else if (e.target.name == 'previsao_termino'){
      
      api.get('/local?pk_loc='+data.fk_loc).then(r=>{
        horaBusca= r.data[0].primeiro_horario.replace(':','.').split(':')[0]
        horaBusca= Number(horaBusca)
        if (horaBusca < horario){
          swal.fire({
                  title: "Erro!",
                  text: "Horário informado fora do período de funcionamento!",
                  icon: "warning",
                }).then(r=>{
                  setErroHorario(true)
                });
        } else{
          setErroHorario(false)
        }
      })
    }
  }

  const incluiMat = async (e) => {
    e.preventDefault();
    setShowModal(true)


    // const auxValues = { ...data };
    // const { value: formValues } = await swal.fire({
    //   title: "",
    //   html: (
    //     <div
    //       style={{
    //         display: "flex",
    //         flex: 1,
    //         marginTop: "20px",
    //         flexDirection: "column",
    //         alignItems: "flex-start",
    //       }}
    //     >
    //       <FrTextInput
    //         maxLength="80"
    //         value={data.nomemat}
    //         color="#49573B"
    //         label="Utensílio"
    //         variant="outlined"
    //         size="small"
    //         disabled
    //         style={{ width: "300px" }}
    //       />

    //       <FrTextInput
    //         maxLength="80"
    //         name="quantidade"
    //         id="quantidade"
    //         color="#49573B"
    //         label="Quantidade"
    //         variant="outlined"
    //         size="small"
    //         required
    //         style={{ width: "100px" }}
    //       />
    //     </div>
    //   ),
    //   focusConfirm: false,
    //   preConfirm: () => {
    //     return {
    //       quantidade: document.getElementById("quantidade").value,
    //     };
    //   },
    //   showCancelButton: true,
    //   cancelButtonText: "Cancelar",
    //   confirmButtonText: "OK",
    //   confirmButtonColor: `#004136`,
    //   allowOutsideClick: false,
    // });

    // if (formValues && formValues["quantidade"]) {
    //   if (formValues["quantidade"] > auxValues["qtd_estoque"]) {
    //     swal.fire({
    //       title: "Erro!",
    //       text: "A quantidade informada é maior do que a quantidade disponível!",
    //       icon: "warning",
    //     });
    //   } else {
    //     if (dataMat[0].display === "") {
    //       setDataMat([
    //         {
    //           value: auxValues["fk_mat"],
    //           display: auxValues["nomemat"],
    //           quantidade: formValues["quantidade"],
    //         },
    //       ]);
    //     } else {
    //       let item = [
    //         {
    //           value: dataMat[0].value,
    //           display: dataMat[0].display,
    //           quantidade: dataMat[0].quantidade,
    //         },
    //       ];

    //       var i;
    //       for (i = 1; i < dataMat.length; i++) {
    //         item.push({
    //           value: dataMat[i].value,
    //           display: dataMat[i].display,
    //           quantidade: dataMat[i].quantidade,
    //         });
    //       }

    //       item.push({
    //         value: auxValues["fk_mat"],
    //         display: auxValues["nomemat"],
    //         quantidade: formValues["quantidade"],
    //       });
    //       setDataMat(item);
    //     }
    //   }
    // } else {
    //   swal.fire({
    //     title: "Erro!",
    //     text: "A quantidade deve ser preenchida!",
    //     icon: "warning",
    //   });
    // }
  };

  const incluiRau = async (e) => {
    e.preventDefault();
    const { value: formValues } = await swal.fire({
      title: "",
      html: (
        <div
          style={{
            display: "flex",
            flex: 1,
            marginTop: "20px",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <FrTextInput
            maxLength="100"
            name="nome"
            id="nome"
            color="#49573B"
            label="Nome"
            variant="outlined"
            size="small"
            required
            style={{ width: "300px" }}
          />

          <FrTextInput
            maxLength="80"
            name="cpf"
            id="cpf"
            color="#49573B"
            label="CPF"
            variant="outlined"
            size="small"
            style={{ width: "200px" }}
          />

          <FrTextInput
            maxLength="80"
            name="cnpj"
            id="cnpj"
            color="#49573B"
            label="CNPJ"
            variant="outlined"
            size="small"
            style={{ width: "200px" }}
          />
        </div>
      ),
      focusConfirm: false,
      preConfirm: () => {
        return {
          nome: document.getElementById("nome").value.toUpperCase(),
          cpf: mCPF(document.getElementById("cpf").value),
          cnpj: mCNPJ(document.getElementById("cnpj").value),
        };
      },
      showCancelButton: false,
      cancelButtonText: "Cancelar",
      confirmButtonText: "OK",
      confirmButtonColor: `#004136`,
      allowOutsideClick: false,
    });

    if (
      formValues &&
      formValues["nome"]
    ) {
      if (dataRau[0].display === "") {
        setDataRau([
          {
            display: formValues["nome"].toUpperCase(),
            cpf: mCPF(formValues["cpf"]),
            cnpj: mCNPJ(formValues["cnpj"]),
          },
        ]);
      } else {
        let item = [
          {
            display: dataRau[0].display,
            cpf: dataRau[0].cpf,
            cnpj: dataRau[0].cnpj,
          },
        ];

        var i;
        for (i = 1; i < dataRau.length; i++) {
          item.push({
            display: dataRau[i].display,
            cpf: dataRau[i].cpf,
            cnpj: dataRau[i].cnpj,
          });
        }

        item.push({
          display: formValues["nome"].toUpperCase(),
          cpf: mCPF(formValues["cpf"]),
          cnpj: mCNPJ(formValues["cnpj"]),
        });
        setDataRau(item);
      }
    } else {
      swal.fire({
        title: "Erro!",
        text: "Informe o nome!",
        icon: "warning",
      });
    }
  };

  return (
    <div className="defaultReg">
      <NavBar />
      <div className="defaultRegContent">
        <div className="defaultHeaderReg">
          <UserOptions />
          <h3>
            {" "}
            FORMULÁRIO DE REQUERIMENTO DE RESERVA DAS DEPENDÊNCIAS DA SEDE
            SOCIOCULTURAL APERGS
          </h3>
        </div>

        <br />
        <form id="regForm" onSubmit={handleSubmit}>
          <div className="divBorder">
            <br />
            <FrTextInput
              value={auth.name || ""}
              label="Associado"
              variant="outlined"
              size="small"
              style={{ width: 400 }}
              disabled
              hidden={auth.pk_ass == 1973}
            />

            <FrSelect
              value={data.fk_ass || ""}
              onChange={handleChange}
              name="fk_ass"
              id="fk_ass"
              label={auth.pk_ass !== 1973 ? "" : "Associado"}
              data={comboAss}
              style={{ width: 400 }}
              hidden={auth.pk_ass !== 1973}
            />

            <FrTextInput
              value={data.email_contato || ""}
              label="Email Contato"
              variant="outlined"
              size="small"
              style={{ width: 300 }}
              onChange={handleChange}
              name="email_contato"
              id="email_contato"
            />

            <FrTextInput
              value={data.fone_contato || ""}
              label="Fone Contato"
              variant="outlined"
              size="small"
              style={{ width: 300 }}
              onChange={handleChange}
              name="fone_contato"
              id="fone_contato"
            />

            <br />
            <hr width="100%" size="50" color="#004136" />
            <br />
            {auth.user == 36 ? <FrTextInput
              maxLength="20"
              value={data.titulo || ""}
              onChange={handleChange}
              name="titulo"
              id="titulo"
              color="#49573B"
              label="Evento"
              variant="outlined"
              size="small"
              required
              style={{ width: 400 }}
              
            />:
            ''}
            

            <FrSelect
              value={data.fk_loc || ""}
              onChange={handleChange}
              name="fk_loc"
              id="fk_loc"
              label="Dependência a ser ocupada"
              data={comboLoc}
              style={{ width: 400 }}
              disabled
            />

            <FrTextInput
              maxLength="20"
              value={data.data || ""}
              onChange={handleChange}
              name="data"
              id="data"
              color="#49573B"
              label="Data Pretendida"
              variant="outlined"
              size="small"
              required
              style={{ width: 150 }}
              disabled
            />
            <FrTextInput
              maxLength="20"
              value={data.hora_inicio || ""}
              onChange={handleChange}
              name="hora_inicio"
              id="hora_inicio"
              color="#49573B"
              label="Hora Início"
              variant="outlined"
              size="small"
              onBlur={verificaHora}
              required
              style={{ width: 130 }}
            />
            <FrTextInput
              maxLength="20"
              value={data.previsao_termino || ""}
              onChange={handleChange}
              name="previsao_termino"
              id="previsao_termino"
              color="#49573B"
              label="Previsão Término"
              variant="outlined"
              size="small"
              onBlur={verificaHora}
              required
              style={{ width: 130 }}
            />
            <FrSelect
              value={data.turno || ""}
              onChange={handleChange}
              name="turno"
              id="turno"
              label="Turno"
              data={comboTurno}
              style={{ width: 100 }}
              disabled={updateType}
            />
            <br />
            <hr width="100%" size="50" color="#004136" />
            <br />
            <div class="row">
              <div style={{ marginLeft: "15px" }}>
                <FrSelect
                  value={data.fk_lva || ""}
                  onChange={handleChange}
                  name="fk_lva"
                  id="fk_lva"
                  label="Finalidade da Ocupação"
                  data={comboFinalidade}
                  style={{ width: 450 }}
                  disabled={updateType && auth.pk_ass !== 1973}
                />
              </div>

              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={exclusiva || ''}
                      checked={data.reserva_exclusiva === 'S'}
                      name='reserva_exclusiva'
                      id='reserva_exclusiva'
                      size='small'
                      onChange={handleChange}
                      color="primary"
                    />
                  }
                  label="Reserva Exclusiva"
                />
              </div>
              {/* <div style={{ marginTop: "8px", marginLeft: "10px" }} hidden={taxa == 0 || data.reserva_exclusiva == "N"}>
                <strong>
                  Taxa de Reserva Exclusiva: R$ {taxa.toFixed(2)}{" "}
                </strong>
              </div> */}
            </div>

            <br />
            <hr width="100%" size="50" color="#004136" />
            <br />

            <FrSelect
              value={data.fk_tpe || ""}
              onChange={handleChange}
              name="fk_tpe"
              id="fk_tpe"
              label="Tipo de Evento"
              data={comboTpe}
              style={{ width: 300 }}
              disabled={updateType}
            />

            <FrTextInput
              maxLength="20"
              value={data.num_pessoas || ""}
              onChange={handleChange}
              name="num_pessoas"
              id="num_pessoas"
              color="#49573B"
              label="Nº de Pessoas"
              variant="outlined"
              size="small"
              required
              style={{ width: 120 }}
            />

            <FrTextInput
              maxLength="20"
              value={valor || ""}
              onChange={handleChange}
              name="valor_locacao"
              id="valor_locacao"
              color="#49573B"
              label="Valor da Locação"
              variant="outlined"
              size="small"
              required
              disabled
              style={{ width: 150 }}
            />
            <br />

            <strong>
              UTENSÍLIOS QUE SERÃO UTILIZADOS (Informe a quantidade dos
              utensílios que serão utilizados)
            </strong>
            <hr width="100%" size="50" color="#004136" />
            <br />

            {/* <FrSelect
              value={data.fk_mat || ""}
              onChange={handleChange}
              name="fk_mat"
              id="fk_mat"
              label="Utensílio"
              data={comboMat}
              style={{ width: 450 }}
              required
            /> */}
            <button className="editButton" onClick={incluiMat}>
              Materiais
            </button>

            <br />


            <div hidden={!showModal}>
              <Modal.Dialog className="Modal">
                <div>
                  <Modal.Body className="ModalBg">

                    <div style={{ height: '100%', width: '100%' }}>
                      <TableEdicao2 data={dataMat2} setDataMat3={setDataMat3} />
                    </div>
                  </Modal.Body>
                </div>
                <Modal.Footer>
                  <div className="regButtonsDiv">
                    <button onClick={escondeModal}> OK </button>
                  </div>
                </Modal.Footer>
              </Modal.Dialog>
            </div>


            <div className="tableDiv" hidden={showModal}>
              <FrTableReg
                setDataMat={setDataMat}
                detail
                regName=""
                columns={columns}
                searched={true}
                codeName="display"
                page={""}
                data={dataMat}
                emptyMessage="Nenhum utensílio solicitado!"
                readonly={true}
              />
            </div>

            <br />

            <strong>
              PESSOAS OU EMPRESAS CONTRATADAS E AUTORIZADAS A INGRESSAREM NA
              SEDE SOCIOCULTURAL:
            </strong>
            <hr width="100%" size="50" color="#004136" />
            <br />

            <button className="editButton" onClick={incluiRau}>
              Incluir
            </button>

            <br />
            <div className="tableDiv">
              <FrTableReg
                setDataRau={setDataRau}
                detail
                regName=""
                columns={columnsRau}
                searched={true}
                codeName="display"
                page={""}
                data={dataRau}
              />
            </div>
          </div>
        </form>
        <div className="regButtonsDiv">
          {enableEdit === "S" ? (
            <button onClick={handleSubmit}>Salvar</button>
          ) : (
            <></>
          )}
          <button onClick={handleCancel}>
            {enableEdit === "S" ? "Cancelar" : "Fechar"}
          </button>
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default Reservas;
