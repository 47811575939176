import React, { useEffect, useState } from 'react';
import Nav from 'react-bootstrap/Nav'
import NavBar from '../components/NavBar'
import { Icon } from 'react-icons-kit'
import { iosPeople } from 'react-icons-kit/ionicons/iosPeople'
import UserOptions from '../components/UserOptions'
import './styles/Home.css';
import FooterDelphus from '../components/FooterDelphus';
import { office } from 'react-icons-kit/icomoon/office'
import { coinDollar } from 'react-icons-kit/icomoon/coinDollar'
import { calendar } from 'react-icons-kit/fa/calendar'
import { fileText2 } from 'react-icons-kit/icomoon/fileText2'

function Home() {
  const [auth, setAuth] = useState(JSON.parse(localStorage['authApergs'] || JSON.stringify({ authenticated: false, user: 0 })));
  let link = "/apergs_associado/associados/registro?codigo=" + auth.pk_ass
  let linkReservas = "/apergs_associado/reservas?pk_ass=" + auth.pk_ass + '&buscar=true'
  return (
    <div className="Home">
      <NavBar />


      <div className="HomeContent">
        <div className="defaultHeader">
          {/* <h3>Seja bem vindo(a), <strong>{auth.name}</strong>. Seu último login foi em {auth.ultimo_login}.</h3> */}
          <UserOptions />
        </div>
        <br />
        <div className="atalhos">
          <Nav.Link href={link} className="atalho">
            <Icon icon={iosPeople} size={40} />
            <strong>{'Dados Cadastrais'}</strong>
          </Nav.Link>

          <Nav.Link href="/apergs_associado/financeiro" className="atalho">
            <Icon icon={coinDollar} size={40} />
            <strong>{'Dados Financeiros'}</strong>
          </Nav.Link>

          <Nav.Link href={linkReservas} className="atalho">
            <Icon icon={office} size={40} />
            <strong>{'Sede Social'}</strong>
          </Nav.Link>

          <Nav.Link href="/apergs_associado/eventos" className="atalho">
            <Icon icon={calendar} size={40} />
            <strong>{'Eventos'}</strong>
          </Nav.Link>

          <Nav.Link href="/apergs_associado/arquivos" className="atalho">
            <Icon icon={fileText2} size={40} />
            <strong>{'Arquivos'}</strong>
          </Nav.Link>

        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default Home;
